import React from "react"
import Layout from "../components/layout"

export default ({ location, data }) => {
  return (
    <Layout location={location}>
      <div className="container">
        <section className="error-404 not-found text-center">
          <header className="page-header">
            <h1 className="page-title">Oops! That page can&rsquo;t be found.</h1>
          </header>

          <div className="page-content">
            <p>Maybe you should try clicking on the wonderful menu links.</p>
          </div>
        </section>
      </div>
    </Layout>
  )
}
